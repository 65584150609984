/* eslint-disable react-hooks/exhaustive-deps */
import React, {memo, useEffect} from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Tenure from './Tenure'
import isEqual from 'react-fast-compare'
import { Console } from 'console'

interface ICampaignRun {
  campaignState: CampaignState
  setCampaignState: React.Dispatch<React.SetStateAction<CampaignState>>
}

const CampaignRun = ({campaignState, setCampaignState}: ICampaignRun) => {
  const [startDate, setStartDate] = React.useState<Date>(new Date())
  const [endDate, setEndDate] = React.useState<Date>(new Date())
  const [tenure, setTenure] = React.useState<string>('1')

  const handleStartDate = (dob: any) => {
    let NewDate = moment(dob).format('YYYY-MM-DD 00:00:00.000');
    setStartDate(dob)
    setCampaignState({...campaignState, startDate: NewDate})
  }


  useEffect(() => {
    if (tenure) {
      const endDate = moment(startDate).add(tenure, 'months').format('YYYY-MM-DD 00:00:00.000')
      setEndDate(new Date(endDate))
      setCampaignState({...campaignState, endDate: endDate})
    }
  }, [campaignState?.startDate, tenure])

  const handleClass = () => {
    const datepicker = document.querySelector('.react-datepicker-popper')
    //@ts-ignore
    const getAttribute = datepicker.getAttribute('style')
    //@ts-ignore
    datepicker.setAttribute('style', `${getAttribute} z-index: 100;`)
  }

  return (
    <div className="row mt-6">
      <div className='col-sm-12 col-md-4'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className='required'>Campaign Start Date</span>
        </label>
        <DatePicker
          className='form-control'
          placeholderText='Select start date'
          timeInputLabel='Time:'
          dateFormat='yyyy-MM-dd'
          minDate={new Date()}
          selected={startDate}
          onSelect={setStartDate}
          onChange={(startDate) => handleStartDate(startDate)}
          startDate={startDate}
          // endDate={endDate}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          onInputClick={handleClass}
        />
      </div>
      <div className='col-sm-12 col-md-4'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className='required'>Campaign End Date</span>
        </label>
        <DatePicker
          className='form-control w-100'
          placeholderText='Select end date'
          timeInputLabel='Time:'
          dateFormat='yyyy-MM-dd'
          //@ts-ignore
          selected={endDate}
          onChange={(endDate) => {}}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          onInputClick={handleClass}
          disabled={true}
        />
      </div>
      <Tenure setTenure={setTenure} tenure={tenure} />
    </div>
  )
}

export default memo(CampaignRun, isEqual)
