/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, FormEvent, useRef} from 'react'

import TimeAgo from 'timeago-react'
import * as timeago from 'timeago.js'

// import it first.
import en_US from 'timeago.js/lib/lang/en_US'

import clsx from 'clsx'

import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from '../../../_metronic/helpers'

// import { useAuth } from '../core/Auth'
import {useAuth} from '../../modules/auth/core/Auth'

import {
  FirebaseDB,
  getDatabase,
  ref,
  set,
  get,
  onValue,
  child,
  push,
  update,
} from '../../../config/firebase'

import {KTSVG} from '../../../_metronic/helpers'
import {ChatInner} from '../../../_metronic/partials/chat/ChatInner'

import {Switch} from '@headlessui/react'
import {parseCookies} from 'nookies'

import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Form from 'react-bootstrap/Form'
import {Button} from 'react-bootstrap'

import {baseUrl, APIURL} from '../../../config/config'
import {hide} from '@popperjs/core'
import {uploadImage} from '../../utils/makeFileToBase64'

const API_URL = process.env.REACT_APP_API_URL

const db = getDatabase()
const dbRef = ref(getDatabase())

const bufferMessages = defaultMessages

interface ChatBoxPropos {
  myAccount?: any
}

const AdminChatBox: React.FC<ChatBoxPropos> = (props: ChatBoxPropos) => {
  const {myAccount} = props

  const {currentUser} = useAuth()
  const cookies = parseCookies()
  const token = cookies?.token

  // console.log('currentUser', currentUser)

  const isDrawer = true
  const timestamp = Date.now()
  const [loading, setLoading] = useState(true)
  const [chatData, setChatData] = useState<any>([])
  const [loadingMSG, setLoadingMSG] = useState(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState([] as any)
  const [image, setImage] = useState('')

  const messagesEndRef = useRef<null | HTMLElement>(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'})
  }

  const updateMessage = (e: {target: {name: any; value: any}}) => {
    setMessage(e.target.value)
  }

  const getMessages = () => {
    get(child(dbRef, `support_chats/admin_${myAccount?.id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val()
          setChatData(data)
        } else {
          setChatData([])
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  get(child(dbRef, `support_chats/admin_${myAccount?.id}/messages`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        setLoadingMSG(false)
        // const data = snapshot.val();
        // console.log('exists messages', data)
        let newMsg: any = []
        snapshot.forEach(function (item: any) {
          var itemVal = item.val()
          newMsg.push({
            admin_id: itemVal.admin_id,
            admin_name: itemVal.admin_name,
            admin_image: itemVal.admin_image,
            admin_status: itemVal.admin_status,
            business_id: itemVal.business_id,
            business_name: itemVal.business_name,
            business_image: itemVal.business_image,
            business_status: itemVal.business_status,
            channel_name: itemVal.channel_name,
            influencer_id: itemVal.influencer_id,
            influencer_name: itemVal.influencer_name,
            influencer_image: itemVal.influencer_image,
            influencer_status: itemVal.influencer_status,
            timestamp: itemVal.timestamp,
            message: itemVal.message,
            send_msg: itemVal.send_msg,
            seen_msg: itemVal.seen_msg,
            seen: itemVal.seen,
            user_type: itemVal.user_type,
            message_image: itemVal.message_image,
          })
        })
        setMessages(newMsg)
        setLoadingMSG(true)
      } else {
        // console.log('not exists messages')
        setLoadingMSG(false)
        setMessages([])
      }
    })
    .catch((error) => {
      console.error(error)
    })

  const NewChatPost = (postData: any) => {
    set(ref(db, `/support_chats/${postData.channel_name}`), {...postData})
      .then(() => {
        getMessages()
      })
      .catch((error) => {
        getMessages()
      })
  }

  const MessageSend = (channel: any, postData: any) => {
    const db = getDatabase()
    const newPostKey = push(child(ref(db), 'support_chats')).key
    set(ref(db, `/support_chats/${channel}/messages/${newPostKey}`), {...postData})
      .then(() => {
        getMessages()
      })
      .catch((error) => {
        getMessages()
      })
    scrollToBottom()
  }

  const chatStart = () => {
    setLoading(true)
    const channel_name = `admin_${myAccount?.id}`
    const postData = {
      admin_id: '',
      admin_name: '',
      admin_image: '',
      admin_status: false,
      business_id: `${myAccount?.id}`,
      business_name: `${myAccount?.f_name} ${myAccount?.l_name}`,
      business_image: `${
        myAccount?.profile_photo == null
          ? 'https://elitelist.spacecats.tech/media/avatars/300-1.jpg'
          : myAccount?.profile_photo
      }`,
      business_status: true,
      channel_name: channel_name,
      influencer_id: '',
      influencer_name: '',
      influencer_image: '',
      influencer_status: false,
      timestamp: timestamp,
      user_type: 'business_owner',
    }

    get(child(dbRef, `support_chats/${channel_name}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val()
          getMessages()
        } else {
          NewChatPost(postData)
        }
      })
      .catch((error) => {
        console.error(error)
      })
    setLoading(false)
  }

  useEffect(() => {
    chatStart()
  }, [myAccount])

  async function SubmitHandler(e: FormEvent) {
    e.preventDefault()
    // console.log(e);
    const channel_name = `admin_${myAccount?.id}`
    let postData = {
      admin_id: '',
      admin_name: '',
      admin_image: '',
      admin_status: false,
      business_id: `${myAccount?.id}`,
      business_name: `${myAccount?.f_name} ${myAccount?.l_name}`,
      business_image: `${
        myAccount?.profile_photo == null
          ? 'https://elitelist.spacecats.tech/media/avatars/300-1.jpg'
          : myAccount?.profile_photo
      }`,
      business_status: true,
      channel_name: channel_name,
      influencer_id: '',
      influencer_name: '',
      influencer_image: '',
      influencer_status: false,
      timestamp: timestamp,
      message: message,
      send_msg: myAccount?.id,
      seen_msg: '',
      seen: false,
      user_type: 'business_owner',
      message_image: image ? await uploadImage(image, 'image', 'support_ticket') : '',
    }

    MessageSend(channel_name, postData)
    setMessage('')
    setImage('')
  }

  return (
    <>
      {loading ? null : (
        <div className='card' id='kt_chat_messenger'>
          <div className='card-header'>
            <div className='card-title'>
              <div className='d-flex justify-content-center flex-column me-3'>
                <span className='fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                  Admin
                </span>
                <div className='mb-0 lh-1'>
                  <span className='badge badge-success badge-circle w-10px h-10px me-1' />
                  <span className='fs-7 fw-semibold text-muted'>Active</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='card-body'
            id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
            style={{height: '50vh', overflow: 'auto', overflowX: 'scroll'}}
          >
            <div
              className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer})}
              data-kt-element='messages'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies={
                isDrawer
                  ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                  : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
              }
              data-kt-scroll-wrappers={
                isDrawer
                  ? '#kt_drawer_chat_messenger_body'
                  : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
              }
              data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
              style={{height: '66vh'}}
            >
              {loadingMSG ? (
                <>
                  {messages?.map((message: any, index: any) => {
                    const state = message.user_type === 'business_owner' ? 'info' : 'primary'
                    const templateAttr = {}
                    if (message.template) {
                      Object.defineProperty(templateAttr, 'data-kt-element', {
                        value: `template-${message.user_type}`,
                      })
                    }
                    const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
                      message.user_type === 'business_owner' ? 'start' : 'end'
                    } mb-10`

                    return (
                      <div
                        key={`message${index}`}
                        className={clsx('d-flex', contentClass, 'mb-10', {
                          'd-none': message.template,
                        })}
                        {...templateAttr}
                      >
                        <div
                          className={clsx(
                            'd-flex flex-column align-items',
                            `align-items-${
                              message.user_type === 'business_owner' ? 'start' : 'end'
                            }`
                          )}
                          data-kt-element={`${
                            message.user_type === 'business_owner' ? 'template-out' : 'template-in'
                          }`}
                          //@ts-ignore
                          ref={messagesEndRef}
                        >
                          <div className='d-flex align-items-center mb-2'>
                            {message.user_type === 'business_owner' ? (
                              <>
                                <div className='symbol  symbol-35px symbol-circle '>
                                  <img alt='Pic' src={message.business_image} />
                                </div>
                                <div className='ms-3'>
                                  <a
                                    href='#'
                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                                  >
                                    {message.account_manger_name}
                                  </a>
                                  <span className='text-muted fs-7 mb-1'>
                                    <TimeAgo datetime={message.timestamp} locale='en_US' />
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='me-3'>
                                  <span className='text-muted fs-7 mb-1'>
                                    <TimeAgo datetime={message.timestamp} locale='en_US' />
                                  </span>
                                  <a
                                    href='#'
                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                  >
                                    {message.admin_name}
                                  </a>
                                </div>
                                <div className='symbol  symbol-35px symbol-circle '>
                                  <img alt='Pic' src={message.admin_image} />
                                </div>
                              </>
                            )}
                          </div>

                          <div className={clsx('p-5 rounded', `bg-light-${state}`)}>
                            {message.message && (
                              <div
                                className={clsx(
                                  // 'p-5 rounded',
                                  // `bg-light-${state}`,
                                  'text-dark fw-bold mw-lg-400px',
                                  `text-${message.user_type === 'business_owner' ? 'start' : 'end'}`
                                )}
                                data-kt-element='message-text'
                                dangerouslySetInnerHTML={{__html: message.message}}
                              ></div>
                            )}
                            {message.message_image && (
                              <div className='d-flex align-items-center mt-2'>
                                <img
                                  src={message.message_image}
                                  alt='preview'
                                  style={{
                                    width: '80px',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    window.open(message.message_image, '_blank')
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            className='card-footer pt-4'
            id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
            style={{
              // height: '25vh',
              padding: '0px 0px',
            }}
          >
            <form className='p-6' onSubmit={SubmitHandler}>
              <textarea
                className='form-control mb-3'
                rows={1}
                data-kt-element='input'
                placeholder='Type a message'
                name='message'
                value={message}
                onChange={updateMessage}
                required={!image}
              ></textarea>

              <div className='d-flex flex-stack'>
                <div className='d-flex align-items-center'>
                  <span
                    // className='btn btn-primary'
                    className='btn btn-sm btn-icon btn-active-light-primary me-1'
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      display: 'flex',
                      cursor: 'pointer',
                    }}
                  >
                    {/* <i className='fa fa-plus'></i> */}
                    <i className='bi bi-paperclip fs-3'></i>
                    {/* <span> Add Image... </span> */}
                    <input
                      type='file'
                      name='file'
                      accept='image/*'
                      onChange={(e: any) => {
                        e.preventDefault()
                        const file = e.target.files[0]
                        const reader = new FileReader()
                        reader.onloadend = function () {
                          setImage(reader.result as string)
                        }
                        reader.readAsDataURL(file)
                      }}
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        margin: 0,
                        opacity: 0,
                        cursor: 'pointer',
                        height: '35px',
                        width: '35px',
                      }}
                    />
                  </span>
                  {/* <button
                    className='btn btn-sm btn-icon btn-active-light-primary me-1'
                    type='button'
                  >
                    <i className='bi bi-paperclip fs-3'></i>
                  </button> */}
                  {/* <button
                    className='btn btn-sm btn-icon btn-active-light-primary me-1'
                    type='button'
                    data-bs-toggle='tooltip'
                    title='Coming soon'
                  >
                    <i className='bi bi-upload fs-3'></i>
                  </button> */}
                </div>
                <button className='btn btn-primary'>Send</button>
              </div>

              {/* image preview */}
              {image ? (
                <div className='mt-3'>
                  <img
                    src={image}
                    alt='preview'
                    style={{
                      width: '80px',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '8px',
                    }}
                  />
                </div>
              ) : null}
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export default AdminChatBox
