
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getDatabase, ref, set, get, onValue, child, push, update } from "firebase/database";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCJ580VodvMap8G21drkbhaZUfC8zi4SII",
    authDomain: "elite-list-21698.firebaseapp.com",
    projectId: "elite-list-21698",
    storageBucket: "elite-list-21698.appspot.com",
    messagingSenderId: "610113878580",
    appId: "1:610113878580:web:aa4f919dbc2e5d6ecfa861",
    measurementId: "G-KK1LR2WKP7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const FirebaseDB = getFirestore(app)

export { FirebaseDB, getDatabase, ref, set, get, onValue, child, push, update };
